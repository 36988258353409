import { defineStore } from "pinia";
import { AuthService } from "../services/auth.service";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    user: null,
  }),
  actions: {
    login(credentials) {
      return AuthService.login(credentials);
    },
    logout() {
      return AuthService.logout();
    },
  },
});
