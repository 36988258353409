<template>
  <v-dialog
    :value="value"
    width="616"
    style="z-index: 20001"
    persistent
    @click:outside="onCancel"
  >
    <v-card class="user-edit-modal pa-5">
      <v-card-title class="text-h5 lighten-2 pa-0">
        {{ modalTitle }}
      </v-card-title>

      <v-form class="mt-5" @submit.prevent="onSubmit">
        <v-text-field
          :value="formData.name"
          :error-messages="nameErrors"
          :loading="loading"
          type="text"
          clearable
          label="Position name"
          outlined
          placeholder="Enter position name"
          class="mb-2"
          @input="onTextFieldsInput($event, 'name')"
          @blur="$v.formData.name.$touch()"
        />
      </v-form>

      <v-card-actions class="pa-0 mt-4 d-flex justify-end">
        <v-btn color="primary" :disabled="loading" text @click="onCancel">
          Cancel
        </v-btn>
        <v-btn
          :disabled="$v.$invalid || !$v.$dirty"
          :loading="loading"
          color="primary"
          rounded
          normal
          variant="elevated"
          @click="onSubmit"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import {
  createClinicianPosition,
  getClinicianPositionById,
  updateClinicianPositionById,
} from "@/services/accountConfiguration";

export default {
  name: "EditClinicianPositionModal",

  mixins: [validationMixin],

  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
    clinicianPositionId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      formData: {},
    };
  },

  validations: {
    formData: {
      name: { required, minLength: minLength(2) },
    },
  },

  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.formData.name.$dirty) return errors;
      !this.$v.formData.name.required && errors.push("Name is required");
      !this.$v.formData.name.minLength &&
        errors.push("The name must have a minimum 2 characters");
      return errors;
    },
    modalTitle() {
      return this.formData._id ? "Edit position" : "Create position";
    },
  },

  watch: {
    value(newValue) {
      if (newValue) {
        this.initModalData();
      }
    },
  },

  methods: {
    async initModalData() {
      if (!this.clinicianPositionId) {
        this.formData = { name: "" };
        return;
      }

      try {
        this.formData = await getClinicianPositionById(
          this.clinicianPositionId
        );
      } catch (error) {
        this.$notify({
          type: "error",
          title: "Create Failed",
          text: error.response?.data?.message || error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    onCancel() {
      this.reset();
      this.toggleDialog();
    },
    toggleDialog() {
      this.$emit("input", !this.value);
    },
    async onSubmit() {
      if (this.formData._id) {
        try {
          this.loading = true;
          await updateClinicianPositionById(this.formData._id, this.formData);
          this.onCancel();
        } catch (error) {
          this.$notify({
            type: "error",
            title: "Update Failed",
            text: error.response?.data?.message || error.message,
          });
        } finally {
          this.loading = false;
        }
        return;
      }
      try {
        this.loading = true;
        await createClinicianPosition(this.formData);
        this.onCancel();
      } catch (error) {
        this.$notify({
          type: "error",
          title: "Creation Failed",
          text: error.response?.data?.message || error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    onTextFieldsInput(value, key) {
      this.formData[key] = value;
      this.$v.formData[key].$touch();
    },
    reset() {
      this.$v.$reset();
      this.formData = {};
    },
  },
};
</script>
