<template>
  <v-menu v-model="isMenuActive" :disabled="!isMultiAccount" offset-y>
    <template v-slot:activator="{ on, attributes }">
      <v-chip outlined v-bind="attributes" v-on="on">
        <b class="AccountDropdown__Label">{{ currentAccountName }}</b>
        <v-icon v-if="isMultiAccount" color="black" right>
          {{ menuIcon }}
        </v-icon>
      </v-chip>
    </template>

    <!-- TODO: Ininite loader  -->
    <v-list v-if="isMultiAccount" :max-height="390">
      <v-subheader class="font-weight-bold">Accounts</v-subheader>
      <v-list-item
        v-for="account in accounts"
        :key="account._id"
        class="AccountDropdown__Item"
        @click="onAccountSelect(account._id)"
      >
        <v-list-item-title>{{ account.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState } from "pinia";
import { useUserStore } from "@/stores/user";

export default {
  name: "AccountDropdown",

  data: () => ({
    isMenuActive: false,
  }),

  computed: {
    ...mapState(useUserStore, ["user", "currentAccount", "accounts"]),
    menuIcon() {
      return this.isMenuActive ? "mdi-menu-up" : "mdi-menu-down";
    },
    currentAccountName() {
      return this.currentAccount?.name || "";
    },
    isMultiAccount() {
      return this.accounts.length > 1;
    },
  },

  methods: {
    onAccountSelect(accountId) {
      localStorage.setItem("accountId", accountId);
      location.replace("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.AccountDropdown {
  &__Label {
    max-width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__Item {
    background-color: #fff;
  }
}
</style>
