import { axiosInstance } from "../setupInterceptors";

const endpointUrl = "/users";

export const activateUser = (id) => {
  return axiosInstance
    .put(`${endpointUrl}/${id}/activate`)
    .then((response) => response.data);
};

export const deactivateUser = (id) => {
  return axiosInstance
    .put(`${endpointUrl}/${id}/deactivate`)
    .then((response) => response.data);
};

export const getClinicians = (params) => {
  return axiosInstance
    .get(`${endpointUrl}/clinicians`, { params })
    .then((response) => response.data);
};

export const getAllClinicians = (params) => {
  return axiosInstance
      .get(`${endpointUrl}/all-clinicians`, { params })
      .then((response) => response.data);
};

export const getManagers = (params) => {
  return axiosInstance
    .get(`${endpointUrl}/managers`, { params })
    .then((response) => response.data);
};

export const getClinicianStatistic = (id) => {
  return axiosInstance
    .get(`${endpointUrl}/${id}/cases-statistic`)
    .then((response) => response.data);
};

export const getCurrentUser = () => {
  return axiosInstance
    .get(`${endpointUrl}/current`)
    .then((response) => response.data);
};

export const getUserById = (id) => {
  return axiosInstance
    .get(`${endpointUrl}/${id}`)
    .then((response) => response.data);
};

export const getClinicianById = (id) => {
  return axiosInstance
    .get(`${endpointUrl}/clinician/${id}`)
    .then((response) => response.data);
};

export const getUsers = (params) => {
  return axiosInstance
    .get(endpointUrl, { params })
    .then((response) => response.data);
};

export const updateUserById = (id, body) => {
  return axiosInstance
    .put(`${endpointUrl}/${id}`, body)
    .then((response) => response.data);
};

export const getUserDynamic = (id) => {
  return axiosInstance
    .get(`${endpointUrl}/${id}/dynamic`)
    .then((response) => response.data);
};

export const getClinicianCases = (id, params) => {
  return axiosInstance
    .get(`${endpointUrl}/${id}/cases`, { params })
    .then((response) => response.data);
};

export const getUserAccounts = (id) => {
  return axiosInstance
    .get(`${endpointUrl}/${id}/accounts`)
    .then((response) => response.data);
};

export const getCliniciansTable = (params) => {
  return axiosInstance
    .get(`${endpointUrl}/clinicians/export`, { params })
    .then((response) => response.data);
};
