export default function dateToLocalString(date, includeHM = true) {
  const options = {
    day: "2-digit",
    month: "short",
    year: "numeric",
    timeZone: "UTC",
  };

  if (includeHM) {
    options.hour = "2-digit";
    options.minute = "2-digit";
  }

  return new Date(date).toLocaleDateString(undefined, options);
}
