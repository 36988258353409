<template>
  <section class="header-profile d-flex flex-row justify-start align-center">
    <v-avatar class="mr-3">
      <v-img :src="userProfileImage" :alt="user.name" />
    </v-avatar>

    <p class="mb-auto mt-auto mr-2">
      <b>{{ userLogin }}</b>
    </p>

    <v-menu v-model="isMenuActive" offset-y>
      <template v-slot:activator="{ on, attributes }">
        <v-icon v-bind="attributes" color="black" v-on="on">
          {{ menuIcon }}
        </v-icon>
      </template>

      <v-list>
        <v-list-item
          v-for="item in userActions"
          :key="item.title"
          @click="item.action"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </section>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useUserStore } from "@/stores/user";
import DefaultAvatar from "@/assets/unknown-user.png";
import { useAuthStore } from "@/stores/auth";

export default {
  name: "HeaderProfile",

  data: () => ({
    isMenuActive: false,
  }),
  computed: {
    ...mapState(useUserStore, ["user"]),
    userActions() {
      const logOutItem = {
        title: "Log Out",
        action: () => {
          this.$auth.logout();
        },
      };

      return [logOutItem];
    },
    userLogin() {
      return `${this.user.name}`;
    },
    userProfileImage() {
      return DefaultAvatar;
    },
    menuIcon() {
      return this.isMenuActive ? "mdi-menu-up" : "mdi-menu-down";
    },
  },

  methods: {
    ...mapActions(useAuthStore, ["logout"]),
  },
};
</script>
