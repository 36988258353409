import { defineStore } from "pinia";
import { getCurrentUser, getUserAccounts } from "../services/users";
import { UserRoles } from "../misc/constants";

export const useUserStore = defineStore("user", {
  state() {
    return {
      user: null,
      accounts: [],
      currentAccountId: null,
    };
  },
  actions: {
    setUser(user) {
      this.user = user || null;
    },
    setCurrentAccountId(accountId) {
      this.currentAccountId = accountId;
    },
    async getCurrentUser() {
      const user = await getCurrentUser();
      this.user = user;
    },
    async getUserAccounts() {
      this.accounts = await getUserAccounts(this.user._id);
      return this.accounts;
    },
  },
  getters: {
    currentAccount: (state) => {
      return state.accounts.find(({ _id }) => _id === state.currentAccountId);
    },
    isSuperAdmin: (state) => Boolean(state.user?.isAdmin),
    isAdmin: (state) => state.currentAccount?.role === UserRoles.admin,
    isAuditor: (state) => state.currentAccount?.role === UserRoles.auditor,
    isClinician: (state) => state.currentAccount?.role === UserRoles.doctor,
    isManager: (state) => state.currentAccount?.role === UserRoles.manager,
  },
});
