<template>
  <header class="app-header">
    <div class="app-container">
      <div
        v-if="!$vuetify.breakpoint.mobile"
        class="d-flex flex-row justify-space-between align-center"
      >
        <section
          class="app-header__navigation d-flex flex-row justify-start align-center"
        >
          <router-link to="/" class="d-flex justify-center align-center mr-6">
            <logo-icon />
          </router-link>

          <nav class="d-flex">
            <router-link
              v-for="link in userNavigationLinks"
              :key="link.title"
              :to="link.path"
              class="nav-link mr-4 d-flex justify-center align-center"
              exact
            >
              {{ link.title }}
            </router-link>

            <v-menu
              v-if="isAdmin || isAuditor || isManager"
              v-model="isMenuActive"
              offset-y
              left
              class="nav-audit-menu mr-4"
            >
              <template v-slot:activator="{ on, attributes }">
                <a
                  v-bind="attributes"
                  class="nav-link pa-0 d-flex justify-center align-center mr-2"
                  v-on="on"
                >
                  Audit
                  <v-icon color="black">
                    {{ isMenuActive ? "mdi-menu-up" : "mdi-menu-down" }}
                  </v-icon>
                </a>
              </template>

              <v-list>
                <v-list-item v-for="link in auditLinks" :key="link.title">
                  <router-link
                    :key="link.title"
                    :to="link.path"
                    class="text-decoration-none text-subtitle-1 black--text"
                    exact
                  >
                    {{ link.title }}
                  </router-link>
                </v-list-item>
              </v-list>
            </v-menu>

            <router-link
              v-if="!isClinician"
              :to="reportingLink.path"
              class="nav-link mr-4 d-flex justify-center align-center"
              exact
            >
              {{ reportingLink.title }}
            </router-link>

            <v-menu
              v-if="isAdmin"
              v-model="isSettingsMenuActive"
              offset-y
              left
              class="nav-settings-menu mr-4"
            >
              <template v-slot:activator="{ on, attributes }">
                <a
                  v-bind="attributes"
                  class="nav-link pa-0 d-flex justify-center align-center mr-2"
                  v-on="on"
                >
                  Settings
                  <v-icon color="black">
                    {{ isSettingsMenuActive ? "mdi-menu-up" : "mdi-menu-down" }}
                  </v-icon>
                </a>
              </template>

              <v-list>
                <v-list-item v-for="link in settingsLinks" :key="link.title">
                  <router-link
                    :key="link.title"
                    :to="link.path"
                    class="text-decoration-none text-subtitle-1 black--text"
                    exact
                  >
                    {{ link.title }}
                  </router-link>
                </v-list-item>
              </v-list>
            </v-menu>
          </nav>
        </section>
        <div class="d-flex align-center">
          <img
            v-if="accountLogoUrl"
            :src="accountLogoUrl"
            id="logo-image"
            alt="Account Logo"
            style="height: 28px"
            @load="imageLoadedHanlder"
          />
          <div class="mr-6 ml-5">
            <AccountDropdown />
          </div>
          <HeaderProfile />
        </div>
      </div>
      <div v-else class="d-flex flex-row justify-space-between align-center">
        <router-link to="/" class="d-flex justify-center align-center mr-6">
          <logo-with-text-icon />
        </router-link>

        <a
          class="d-flex justify-center align-center"
          @click.prevent="$emit('click', true)"
        >
          <burger-menu-icon class="app-header__burger-menu-icon" />
        </a>
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { RouterLink } from "vue-router";
import { useUserStore } from "@/stores/user";
import { useAccountStore } from "@/stores/account";
import LogoIcon from "../icons/LogoIcon.vue";
import LogoWithTextIcon from "../icons/LogoWithTextIcon.vue";
import BurgerMenuIcon from "../icons/BurgerMenuIcon.vue";
import HeaderProfile from "./HeaderProfile.vue";
import AccountDropdown from "./AccountDropdown.vue";
import html2canvas from "html2canvas-pro";

export default {
  name: "AppHeader",

  components: {
    RouterLink,
    LogoIcon,
    LogoWithTextIcon,
    BurgerMenuIcon,
    HeaderProfile,
    AccountDropdown,
  },

  data() {
    const reportingLink = {
      title: "Reporting",
      path: "/reporting",
    };
    const navigationLinks = [
      {
        title: "Home",
        path: "/",
      },
      {
        title: "Clinicians",
        path: "/clinicians",
      },
      {
        title: "Cases",
        path: "/cases",
      },
    ];

    const defaultAuditLinks = [
      {
        title: "Clinical Documentation Audit",
        path: "/doc-audit",
      },
      {
        title: "Special Audit",
        path: "/special-audit",
      },
      {
        title: "Audit List",
        path: "/batches",
      },
    ];

    return {
      reportingLink,
      navigationLinks,
      defaultAuditLinks,
      isMenuActive: false,
      isSettingsMenuActive: false,
    };
  },

  methods: {
    ...mapActions(useAccountStore, ["setEncodedImgLogo"]),
    async imageLoadedHanlder() {
      const imageEl = document.getElementById("logo-image");
      const helperCanvas = await html2canvas(imageEl, {
        proxy: this.accountLogoUrl,
        backgroundColor: null,
        scale: 2,
      });
      this.setEncodedImgLogo(helperCanvas.toDataURL("image/png", 1.0));
    },
  },

  computed: {
    ...mapState(useUserStore, [
      "user",
      "isAuditor",
      "isAdmin",
      "isClinician",
      "isSuperAdmin",
      "isManager",
      "currentAccount",
    ]),
    ...mapState(useAccountStore, ["getEncodedLogoImage"]),
    accountLogoUrl() {
      if (!this.currentAccount?.logoUrl) return null;
      const logoUrl = `${process.env.VUE_APP_MEDIA_CDN_URL}/${this.currentAccount?.logoUrl}`;
      return logoUrl;
    },
    currentAccountName() {
      return this.currentAccount?.name;
    },
    userNavigationLinks() {
      if (this.isClinician) {
        return [
          {
            title: "Home",
            path: `/clinicians/${this.user._id}`,
          },
        ];
      }
      return this.navigationLinks;
    },
    auditLinks() {
      if (this.isClinician) return [];
      return this.defaultAuditLinks;
    },
    settingsLinks() {
      const settingsLinks = [
        { title: "Users", path: "/users" },
        { title: "Configuration", path: "/configuration" },
        { title: "Audit Templates", path: "/audit-templates" },
        { title: "Services", path: "/clinical-services" },
      ];
      if (this.isSuperAdmin) {
        settingsLinks.push({ title: "Accounts", path: "/accounts" });
      }

      return settingsLinks;
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/main.scss";

.app-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 300;
  background-color: var(--v-surface-base);
  height: #{$headerHeight};
  -webkit-box-shadow: 0px 5px 5px -5px #c0c0c0c0;
  -moz-box-shadow: 0px 5px 5px -5px #c0c0c0c0;
  box-shadow: 0px 5px 5px -5px #c0c0c0c0;

  .app-container,
  .app-container > div {
    height: 100%;
  }

  &__navigation {
    height: 100%;

    nav {
      height: 100%;

      .nav-link {
        height: 100% !important;
        font-size: 1.2rem;
        text-decoration: none;
        color: #000000 !important;
        font-weight: 500 !important;
        position: relative;

        &.router-link-active:before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          height: 2px;
          width: 100%;
          background-color: var(--v-primary-base);
        }
      }

      button.nav-link {
        &:before {
          content: none;
        }
      }
    }
  }

  &__burger-menu-icon {
    cursor: pointer;
    min-height: 24px;
  }
}
</style>
