<template>
  <v-main class="services">
    <div class="app-container pt-10 pb-3">
      <section
        class="clinical-service__tool-bar d-flex flex-row justify-space-between align-center mb-5"
      >
        <div class="clinical-service__tool-bar__info">
          <h1 class="text-h4 font-weight-bold">
            {{ pageInfo.title }}
          </h1>
          <p class="text-subtitle-1 text-medium-emphasis">
            {{ pageInfo.description }}
          </p>
        </div>

        <div class="d-flex justify-end align-center">
          <v-btn
            v-if="isSuperAdmin"
            color="warning"
            rounded
            normal
            variant="elevated"
            class="ml-3"
            @click="isMultipleModalVisible = true"
          >
            Seed services
          </v-btn>

          <v-btn
            color="primary"
            rounded
            normal
            variant="elevated"
            class="ml-3"
            @click="isEditModalVisible = true"
          >
            Create new service
          </v-btn>
        </div>
      </section>

      <section class="clinical-service__table">
        <v-skeleton-loader
          v-if="firstLoading"
          :loading="loading"
          type="table"
        />
        <v-data-table
          v-if="!firstLoading"
          id="clinical-service-table"
          :headers="tableHeaders"
          :items="clinicalServices"
          :loading="loading"
          :mobile-breakpoint="0"
          :options.sync="options"
          :server-items-length="clinicalServicesTotal"
          :hide-default-footer="isMobile"
          :footer-props="tableFooterOptions"
          multi-sort
          @update:options="handleTableUpdate"
          @click:row="onRowClick"
        >
          <template #item.createdAt="{ item }">
            {{ dateToLocalString(item.createdAt) }}
          </template>

          <template #item.updatedAt="{ item }">
            {{ dateToLocalString(item.updatedAt) }}
          </template>

          <template #progress>
            <v-progress-linear color="primary" indeterminate />
          </template>
        </v-data-table>

        <v-pagination
          v-if="isMobile"
          v-model="options.page"
          :length="paginationLength"
          class="mt-3"
          @input="onPaginationChange"
        />
      </section>
    </div>

    <EditClinicalServiceModal
      v-model="isEditModalVisible"
      :clinical-service-id="activeClinicalServicesId"
    />

    <MultipleServiceModal
      v-on:fetchClinicalServices="fetchClinicalServices"
      v-model="isMultipleModalVisible"
    />
  </v-main>
</template>

<script>
import { mapState } from "pinia";
import { useUserStore } from "@/stores/user";
import { getClinicalServicesWithPaginate } from "@/services/clinicalServices";
import { TableRowsPerPage } from "@/misc/constants";
import EditClinicalServiceModal from "@/components/clinicalServices/EditClinicalServiceModal.vue";
import MultipleServiceModal from "@/components/clinicalServices/MultipleServiceModal.vue";
import dateToLocalString from "@/helpers/dateToLocalString";

export default {
  name: "ClinicalServicesPage",

  components: {
    EditClinicalServiceModal,
    MultipleServiceModal,
  },

  data() {
    const tableHeaders = [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "Creation Date",
        value: "createdAt",
        sortable: true,
      },
      {
        text: "Modification Date",
        value: "updatedAt",
        sortable: true,
      },
    ];
    const pageInfo = {
      title: "Services",
      description: "Here you can create new services or manage existing.",
    };
    const tableFooterOptions = {
      "items-per-page-options": TableRowsPerPage,
    };

    return {
      options: {},
      clinicalServices: [],
      clinicalServicesTotal: null,
      loading: false,
      firstLoading: true,
      pageInfo,
      tableHeaders,
      tableFooterOptions,
      isEditModalVisible: false,
      isMultipleModalVisible: false,
      activeClinicalServicesId: null,
    };
  },

  computed: {
    ...mapState(useUserStore, ["isSuperAdmin"]),
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    paginationLength() {
      if (!this.options || !this.options.itemsPerPage) return 0;
      return Math.ceil(this.clinicalServicesTotal / this.options.itemsPerPage);
    },
  },

  watch: {
    isEditModalVisible(isVisible) {
      if (!isVisible) {
        this.activeClinicalServicesId = null;
        this.fetchClinicalServices();
      }
    },
  },

  async mounted() {
    await this.fetchClinicalServices();
  },

  methods: {
    dateToLocalString,
    async fetchClinicalServices() {
      this.loading = true;
      try {
        const { data, total } = await getClinicalServicesWithPaginate(
          this.buildQuery()
        );

        this.clinicalServices = data;
        this.clinicalServicesTotal = total;
      } catch (error) {
        this.$notify({
          type: "error",
          title: "Service fetch failed",
          text: error.response?.data?.message || error.message,
        });
      } finally {
        this.loading = false;
        this.firstLoading = false;
      }
    },
    buildQuery() {
      const query = {};
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      query.page = page || 1;
      query.pageSize = itemsPerPage || 10;

      if (sortBy && sortDesc && sortBy.length && sortDesc.length) {
        query.sort = sortBy.reduce((acc, el, ind) => {
          const sortBy = `${el}:${sortDesc[ind] ? -1 : 1}`;
          acc += ind === 0 ? sortBy : `,${sortBy}`;
          return acc;
        }, "");
      }

      return query;
    },
    onRowClick(item) {
      this.activeClinicalServicesId = item._id;
      this.isEditModalVisible = true;
    },
    async handleTableUpdate() {
      await this.fetchClinicalServices();
    },
    onPaginationChange(value) {
      this.options.page = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/main.scss";

.clinical-service {
  &__tool-bar {
    &-filter-btn {
      min-width: 48px !important;
      height: 48px !important;

      &--active {
        background-color: #f0f0f0;
        border-radius: 8px;
      }
    }
  }

  &__table {
    .v-tooltip__content {
      position: absolute !important;
    }
  }
}
</style>
