<template>
  <v-data-table
    :headers="tableHeaders"
    :items="items"
    :items-per-page="10"
    :footer-props="tableFooterProps"
    class="ResultsTable elevation-1"
  >
    <template #[`item.status`]="{ item }">
      <v-chip label small :class="`status--${item.status}`">
        {{ item.status }}
      </v-chip>
    </template>

    <template #[`item.error`]="{ item }">
      {{ item.error || "-" }}
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    refHeader: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableFooterProps: {
        "items-per-page-options": [5, 10, 15],
      },
    };
  },
  computed: {
    tableHeaders() {
      return [
        { text: this.refHeader, value: "ref", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Error Type", value: "error", sortable: false },
      ];
    },
  },
};
</script>

<style lang="scss">
.ResultsTable {
  $statuses: (
    "new": #c8e6c9,
    "duplicate": #ffd463,
    "fail": #ff8f8e,
  );

  .v-chip.status {
    @each $status, $color in $statuses {
      &--#{$status} {
        text-transform: capitalize;
        background-color: $color !important;
      }
    }
  }
}
</style>
