import { setupAccountInterceptor } from "./setupInterceptors";
import { useUserStore } from "../stores/user";
import { useAccountStore } from "@/stores/account";

const IS_LOCAL_HOST = location.host.includes("localhost");
let TOKEN = "";

export class AuthService {
  static getBearer() {
    const accessToken = AuthService.getAccessToken();
    return `Bearer ${accessToken}`;
  }

  static getAccessToken() {
    return IS_LOCAL_HOST ? localStorage.getItem("accessToken") : TOKEN;
  }

  static setAccessToken(accessToken) {
    if (IS_LOCAL_HOST) {
      localStorage.setItem("accessToken", accessToken);
    } else {
      TOKEN = accessToken;
    }
  }

  static async fetchUserMemberships() {
    const accountStore = useAccountStore();
    const userStore = useUserStore();
    await userStore.getCurrentUser();
    const accounts = await userStore.getUserAccounts();

    const accountId = localStorage.getItem("accountId") || accounts[0]?._id;

    userStore.setCurrentAccountId(accountId);
    accountStore.setCurrentAccount(accounts.find(acc => acc?._id === accountId));
    setupAccountInterceptor({ accountId });
  }

  static _setAuthData({ accessToken } = {}) {
    AuthService.setAccessToken(accessToken);
  }

  static _resetAuthData() {
    const userStore = useUserStore();
    // reset userData in store
    userStore.setUser(null);
    // reset tokens
    AuthService.setAccessToken("");
  }
}
