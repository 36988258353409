import { axiosInstance } from "../setupInterceptors";

const endpointUrl = "/clinician-position";

const extractData = (response) => response.data;

export const getClinicianPositions = (params = {}) =>
  axiosInstance.get(endpointUrl, { params }).then(extractData);

export const getClinicianPositionById = (id, params = {}) =>
  axiosInstance.get(`${endpointUrl}/${id}`, { params }).then(extractData);

export const createClinicianPosition = (payload) =>
  axiosInstance.post(endpointUrl, payload).then(extractData);

export const updateClinicianPositionById = (id, payload) =>
  axiosInstance.patch(`${endpointUrl}/${id}`, payload).then(extractData);

export const uploadAccountLogo = (file) => {
  const formData = new FormData();
  formData.append("file", file);
  axiosInstance
    .put(`accounts/logo`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(extractData);
};
