<template>
  <div ref="burgerMenu" class="burger-menu">
    <div
      class="burger-menu__header d-flex flex-row justify-space-between align-center"
    >
      <logo-with-text-icon />
      <a class="d-flex justify-center align-center" @click.prevent="closeMenu">
        <burger-menu-close-icon />
      </a>
    </div>
    <v-list flat class="burger-menu__items pa-0">
      <template v-for="item in menuItems">
        <v-list-item
          v-if="!item.links"
          :key="item.title"
          class="pa-3 pl-7 pr-7 white--text"
          @click.prevent="item.action"
        >
          <v-list-item-title class="text-h6">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>

        <v-list-group
          v-else
          :key="item.title"
          v-model="item.active"
          color="white"
          no-action
          class="pb-3 pt-3"
        >
          <template v-slot:activator>
            <v-list-item-content class="pa-0 pl-3 pr-2 white--text">
              <v-list-item-title class="text-h6">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="child in item.links"
            :key="child.title"
            @click.prevent="child.action"
          >
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                {{ child.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { useAuthStore } from "@/stores/auth";
import { useUserStore } from "@/stores/user";
import LogoWithTextIcon from "../icons/LogoWithTextIcon.vue";
import BurgerMenuCloseIcon from "../icons/BurgerMenuCloseIcon.vue";

export default {
  name: "BurgerMenu",

  components: {
    LogoWithTextIcon,
    BurgerMenuCloseIcon,
  },

  computed: {
    ...mapState(useUserStore, [
      "user",
      "isSuperAdmin",
      "isClinician",
      "isAuditor",
      "isManager",
    ]),
    menuItems() {
      const logOutItem = {
        title: "Log Out",
        action: () => {
          this.$auth.logout();
          this.closeMenu();
        },
      };

      if (this.isClinician) {
        return [
          {
            title: "Home",
            action: () => this.handleNavigate(`/clinicians/${this.user._id}`),
          },
          logOutItem,
        ];
      }

      const baseLinks = [
        {
          title: "Home",
          action: () => this.handleNavigate("/"),
        },
        {
          title: "Clinicians",
          action: () => this.handleNavigate("/clinicians"),
        },
        {
          title: "Cases",
          action: () => this.handleNavigate("/cases"),
        },
        {
          title: "Audit",
          active: false,
          links: [
            {
              title: "Clinical Documentation Audit",
              action: () => this.handleNavigate("/doc-audit"),
            },
            {
              title: "Special Audit",
              action: () => this.handleNavigate("/special-audit"),
            },
            {
              title: "Audit List",
              action: () => this.handleNavigate("/batches"),
            },
          ],
        },
      ];

      if (this.isAuditor || this.isManager) {
        return [...baseLinks, logOutItem];
      }

      const adminLinks = [
        {
          title: "Settings",
          active: false,
          links: [
            {
              title: "Users",
              action: () => this.handleNavigate("/users"),
            },
            {
              title: "Configuration",
              action: () => this.handleNavigate("/users"),
            },
            {
              title: "Audit templates",
              action: () => this.handleNavigate("/audit-templates"),
            },
            {
              title: "Services",
              action: () => this.handleNavigate("/clinical-services"),
            },
            ...(this.isSuperAdmin
              ? [
                  {
                    title: "Accounts",
                    action: () => this.handleNavigate("/accounts"),
                  },
                ]
              : []),
          ],
        },
      ];

      return [...baseLinks, ...adminLinks, logOutItem];
    },
  },

  mounted() {
    disableBodyScroll(this.$refs.burgerMenu);
  },

  methods: {
    ...mapActions(useAuthStore, ["logout"]),
    handleNavigate(path) {
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
      this.closeMenu();
    },
    closeMenu() {
      enableBodyScroll(this.$refs.burgerMenu);
      this.$emit("toggle", false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/main.scss";

.burger-menu {
  width: 100%;
  min-height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 301;

  &__header {
    height: #{$headerHeight};
    background-color: var(--v-background-base);
    -webkit-box-shadow: 0px 5px 5px -5px #c0c0c0c0;
    -moz-box-shadow: 0px 5px 5px -5px #c0c0c0c0;
    box-shadow: 0px 5px 5px -5px #c0c0c0c0;
    padding: 16px;
  }

  &__items {
    background-color: var(--v-primary-base);
    height: calc(100% - #{$headerHeight});

    .v-list-item,
    .v-list-group {
      cursor: pointer;
      font-size: 1.3rem;
      color: var(--v-on-primary-base);
      border-bottom: 2px solid #53afb7;

      .v-list-item {
        color: inherit;
        border: none;
      }
    }
  }
}
</style>
