<template>
  <v-dialog
    :value="dialog"
    max-width="1152"
    persistent
    style="z-index: 200013"
    class="import-result-modal"
  >
    <v-card class="pa-5">
      <v-card-title class="text-h5 lighten-2 pa-0 mb-4">
        Import Summary
      </v-card-title>
      <v-card-subtitle class="pa-0">
        <span class="font-weight-bold">{{ filename }}</span> has been
        successfully imported into Audit Angel.
      </v-card-subtitle>

      <v-tabs>
        <v-tab>Cases</v-tab>
        <v-tab>Clinicians</v-tab>
        <v-tab>Clinical Codes</v-tab>

        <v-tab-item>
          <ListSummary
            entity="Cases"
            class="mt-2 mb-4"
            :statistics="result.cases"
          />
          <ImportResultsTable
            ref-header="Case Reference"
            :items="result?.cases?.data"
          />
        </v-tab-item>

        <v-tab-item>
          <ListSummary
            entity="Clinicians"
            class="mt-2 mb-4"
            :statistics="result.clinicians"
          />
          <ImportResultsTable
            ref-header="Clinician Name"
            :items="result?.clinicians?.data"
          />
        </v-tab-item>

        <v-tab-item>
          <ListSummary
            entity="Codes"
            class="mt-2 mb-4"
            :statistics="result.clinicalCodes"
          />
          <ImportResultsTable
            ref-header="Clinical Code"
            :items="result?.clinicalCodes?.data"
          />
        </v-tab-item>
      </v-tabs>

      <v-card-actions class="pa-0">
        <v-spacer />
        <v-btn color="primary" rounded @click="toggleDialogView">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ListSummary from "./ListSummary.vue";
import ImportResultsTable from "./ImportResultsTable.vue";

export default {
  name: "ImportSummaryModal",

  components: {
    ListSummary,
    ImportResultsTable,
  },

  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    result: {
      type: Object,
      default: null,
    },
    filename: {
      type: String,
      default: "",
    },
  },

  methods: {
    toggleDialogView() {
      this.$emit("toggle", !this.dialog);
    },
  },
};
</script>
