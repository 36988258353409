<template>
  <v-card
    width="290"
    :max-height="isImportFinished ? 150 : 120"
    class="import-progress-bar pa-2"
  >
    <section class="d-flex align-center justify-end">
      <v-card-title class="pa-0 mr-3">Cases Import progress</v-card-title>
      <v-progress-circular
        rotate="-90"
        size="50"
        width="10"
        :value="importProgress"
        color="rgb(46, 124, 246)"
      >
        {{ importProgress }}
      </v-progress-circular>
    </section>
    <v-card-actions
      v-if="isImportFinished"
      class="d-flex justify-space-between pa-0 mt-2"
    >
      <v-btn text color="primary" width="45%" @click="reset"> Close </v-btn>
      <v-btn outlined color="primary" width="45%" @click="showResult">
        Show result
      </v-btn>
    </v-card-actions>

    <ImportSummaryModal
      :dialog="isResultModalVisible"
      :result="result"
      :filename="fileName || 'File'"
      @toggle="toggleResultModal"
    />
  </v-card>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useCasesImportStore } from "@/stores/casesImport";
import withPusher from "@/mixins/withPusher";
import ImportSummaryModal from "./ImportSummaryModal";
import { ImportMessageTypes } from "@/misc/constants";

export default {
  name: "ImportProgressBar",

  components: {
    ImportSummaryModal,
  },

  mixins: [withPusher],

  computed: {
    ...mapState(useCasesImportStore, [
      "importProgress",
      "result",
      "channelName",
      "shouldInitializeHandlers",
      "fileName",
    ]),
    ...mapWritableState(useCasesImportStore, [
      "casesImported",
      "casesToImport",
      "isImportFinished",
      "isResultModalVisible",
      "isProgressModalVisible",
    ]),
  },

  watch: {
    shouldInitializeHandlers(newValue) {
      if (newValue) {
        this.initializePusherSubscribers();
      }
    },
  },

  beforeMount() {
    window.addEventListener("beforeunload", this.handlePageLeave);
  },

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.handlePageLeave);
    if (!this.pusherClient) return;
    this.pusherClient.unsubscribe(this.channelName);
  },

  methods: {
    ...mapActions(useCasesImportStore, [
      "showResult",
      "addResultData",
      "reset",
    ]),
    casesImportedHandler(message) {
      if (this.isImportFinished) return;
      const { current, casesToImport, isFinal } = message;
      this.casesImported = current;
      if (!this.casesToImport) {
        this.casesToImport = casesToImport;
      }
      if (isFinal) {
        this.isImportFinished = true;
      }
    },
    importErrorHandler(message) {
      const { error } = message;
      this.$notify({
        type: "error",
        title: "Import process error",
        text: error,
      });
      this.reset();
    },
    casesResultChunkHandler(message) {
      this.addResultData(message);
    },
    initializePusherSubscribers() {
      try {
        if (this.pusherClient.isChannelExist()) return;
        this.pusherClient.subscribe(
          this.channelName,
          ImportMessageTypes.casesImported,
          this.casesImportedHandler
        );
        this.pusherClient.subscribe(
          this.channelName,
          ImportMessageTypes.resultChunk,
          this.casesResultChunkHandler
        );
        this.pusherClient.subscribe(
          this.channelName,
          ImportMessageTypes.importError,
          this.importErrorHandler
        );
      } catch (error) {
        this.$notify({
          type: "error",
          title: "Pusher client error",
          text: "Error while initializing cases import pusher subscribers",
        });
      }
    },
    toggleResultModal(value) {
      this.isResultModalVisible = value;
    },
    handlePageLeave(e) {
      if (this.isImportFinished) return;
      e.preventDefault();
      e.returnValue = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.import-progress-bar {
  position: fixed;
  bottom: 70px;
  left: 10px;
}
</style>
