<template>
  <v-main class="services">
    <div class="app-container pt-10 pb-3">
      <section
        class="account-config__tool-bar d-flex flex-row justify-space-between align-center mb-5"
      >
        <div class="account-config__tool-bar__info">
          <h1 class="text-h4 font-weight-bold">
            {{ pageInfo.title }}
          </h1>
          <p class="text-subtitle-1 text-medium-emphasis">
            {{ pageInfo.description }}
          </p>
        </div>
      </section>
      <section class="account-config__tool-bar d-flex flex-column mb-5">
        <div class="account-config__tool-bar__info">
          <h1 class="text-h5 font-weight-bold">Account Logo</h1>
          <p class="text-subtitle-1 text-medium-emphasis">
            Here you can upload the new Logo or replace existing.
            <br />
            Replacement might take some time. Please wait.
          </p>
        </div>
        <div class="account-config__logo-input">
          <file-input-area
            :value="file"
            :error-messages="fileErrors"
            @change="handleFileChange"
            @blur="$v.file.$touch()"
          />
          <v-btn
            :disabled="$v.$invalid"
            :loading="isLoading"
            color="primary"
            rounded
            normal
            variant="elevated"
            @click="handleSendClick"
          >
            Upload
          </v-btn>
        </div>
      </section>
      <section
        class="account-config__tool-bar d-flex flex-row justify-space-between align-center mb-5"
      >
        <div class="account-config__tool-bar__info">
          <h1 class="text-h5 font-weight-bold">Clinicians positions</h1>
          <p class="text-subtitle-1 text-medium-emphasis">
            Here you can create new positions or manage existing.
          </p>
        </div>
        <div class="d-flex justify-end align-center">
          <v-btn
            color="primary"
            rounded
            normal
            variant="elevated"
            class="ml-3"
            @click="isEditModalVisible = true"
          >
            Create new position
          </v-btn>
        </div>
      </section>

      <section class="account-config__table">
        <v-skeleton-loader
          v-if="firstLoading"
          :loading="isLoading"
          type="table"
        />
        <v-data-table
          v-if="!firstLoading"
          id="clinical-service-table"
          :headers="tableHeaders"
          :items="clinicianPositions"
          :loading="loading"
          :mobile-breakpoint="0"
          :options.sync="options"
          :server-items-length="clinicianPositionsTotal"
          :hide-default-footer="isMobile"
          :footer-props="tableFooterOptions"
          multi-sort
          @update:options="handleTableUpdate"
          @click:row="onRowClick"
        >
          <template #item.createdAt="{ item }">
            {{ dateToLocalString(item.createdAt) }}
          </template>

          <template #item.updatedAt="{ item }">
            {{ dateToLocalString(item.updatedAt) }}
          </template>

          <template #progress>
            <v-progress-linear color="primary" indeterminate />
          </template>
        </v-data-table>

        <v-pagination
          v-if="isMobile"
          v-model="options.page"
          :length="paginationLength"
          class="mt-3"
          @input="onPaginationChange"
        />
      </section>
    </div>

    <EditClinicianPositionModal
      v-model="isEditModalVisible"
      :clinician-position-id="activeClinicalPositionId"
    />
  </v-main>
</template>

<script>
import {
  getClinicianPositions,
  uploadAccountLogo,
} from "@/services/accountConfiguration";
import { TableRowsPerPage } from "@/misc/constants";
import dateToLocalString from "@/helpers/dateToLocalString";
import FileInputArea from "@/components/controls/FileInputArea.vue";
import EditClinicianPositionModal from "@/components/clinicianPosition/EditClinicianPositionModal.vue";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  name: "AccountConfiguration",

  components: {
    EditClinicianPositionModal,
    FileInputArea,
  },

  mixins: [validationMixin],

  data() {
    const tableHeaders = [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "Creation Date",
        value: "createdAt",
        sortable: true,
      },
      {
        text: "Modification Date",
        value: "updatedAt",
        sortable: true,
      },
    ];
    const pageInfo = {
      title: "Account configuration",
      description: "Here you can configure account.",
    };
    const tableFooterOptions = {
      "items-per-page-options": TableRowsPerPage,
    };

    return {
      options: {},
      clinicianPositions: [],
      clinicianPositionsTotal: null,
      loading: false,
      firstLoading: true,
      pageInfo,
      tableHeaders,
      tableFooterOptions,
      isEditModalVisible: false,
      activeClinicalPositionId: null,
      file: null,
      filename: "",
      isLoading: false,
      fileTypes: ["image/png", "image/jpg", "image/jpeg"],
    };
  },
  validations: {
    file: {
      required,
      type: function (value) {
        if (!value) return true;
        return this.fileTypes.includes(value.type);
      },
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    paginationLength() {
      if (!this.options || !this.options.itemsPerPage) return 0;
      return Math.ceil(
        this.clinicianPositionsTotal / this.options.itemsPerPage
      );
    },
    fileErrors() {
      const errors = [];
      if (this.file === null || !this.$v.file.$dirty) return errors;
      !this.$v.file.required && errors.push("File is required");
      !this.$v.file.type && errors.push("The file type should be png or jpg");
      return errors;
    },
  },

  watch: {
    isEditModalVisible(isVisible) {
      if (!isVisible) {
        this.activeClinicalPositionId = null;
        this.fetchClinicianPositions();
      }
    },
  },

  async mounted() {
    await this.fetchClinicianPositions();
  },

  methods: {
    dateToLocalString,
    handleFileChange(file) {
      this.file = file;
      this.filename = file?.name || "";
      this.$v.file.$touch();
    },
    async handleSendClick() {
      try {
        this.isLoading = true;
        await uploadAccountLogo(this.file);
        this.$notify({
          type: "success",
          title: "Logo upload",
          text: "Logo upload process started",
        });
      } catch (e) {
        this.reset();
        this.$notify({
          type: "error",
          title: "Logo upload error",
          text: e?.response?.data?.message || JSON.stringify(e),
        });
      } finally {
        this.file = null;
        this.isLoading = false;
      }
    },
    reset() {
      this.$v.$reset();
      this.file = null;
    },
    async fetchClinicianPositions() {
      this.loading = true;
      try {
        const query = this.buildQuery();
        const { data, total } = await getClinicianPositions(query);

        this.clinicianPositions = data;
        this.clinicianPositionsTotal = total;
      } catch (error) {
        this.$notify({
          type: "error",
          title: "Positions fetch failed",
          text: error.response?.data?.message || error.message,
        });
      } finally {
        this.loading = false;
        this.firstLoading = false;
      }
    },
    buildQuery() {
      const query = {};
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      query.page = page || 1;
      query.pageSize = itemsPerPage || 10;

      if (sortBy && sortDesc && sortBy.length && sortDesc.length) {
        query.sort = sortBy.reduce((acc, el, ind) => {
          const sortBy = `${el}:${sortDesc[ind] ? -1 : 1}`;
          acc += ind === 0 ? sortBy : `,${sortBy}`;
          return acc;
        }, "");
      }

      return query;
    },
    onRowClick(item) {
      this.activeClinicalPositionId = item._id;
      this.isEditModalVisible = true;
    },
    async handleTableUpdate() {
      await this.fetchClinicianPositions();
    },
    onPaginationChange(value) {
      this.options.page = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/main.scss";

.account-config {
  &__tool-bar {
    &-filter-btn {
      min-width: 48px !important;
      height: 48px !important;

      &--active {
        background-color: #f0f0f0;
        border-radius: 8px;
      }
    }
  }
  &__logo-input {
    width: 300px;
  }
  &__table {
    .v-tooltip__content {
      position: absolute !important;
    }
  }
}
</style>
