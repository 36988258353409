import Pusher from "pusher-js";

Pusher.logToConsole = process.env?.NODE_ENV !== "production";

export default class PusherClient {
  static instance = null;

  constructor() {
    if (PusherClient.instance) return;
    const appKey = process.env?.VUE_APP_PUSHER_KEY;
    const cluster = process.env?.VUE_APP_PUSHER_CLUSTER;
    PusherClient.instance = new Pusher(appKey, {
      cluster: cluster || "eu",
    });
  }

  subscribe(channelName, eventName, handler) {
    const channel = PusherClient.instance.subscribe(channelName);
    channel.bind(eventName, handler);
  }

  unsubscribe(channelName) {
    PusherClient.instance.unsubscribe(channelName);
  }

  isChannelExist(channelName) {
    const channels = this.getChannels();
    return channels.find((channel) => channel?.name === channelName);
  }

  getChannels() {
    return PusherClient.instance.allChannels();
  }
}
