import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const lightTheme = {
  background: "#FFFFFF",
  surface: "#FFFFFF",
  primary: "#01C6D0",
  "primary-darken-1": "#3700B3",
  secondary: "#03DAC6",
  "secondary-darken-1": "#018786",
  error: "#B00020",
  info: "#2196F3",
  success: "#4CAF50",
  warning: "#FB8C00",
  "on-primary": "#FFFFFF",
  "table-header-background": "#EBFAFB",
  "text-black": "#000000",
};

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: "md",
    thresholds: {
      xs: 0,
      sm: 390,
      md: 768,
      lg: 992,
      xl: 1152,
    },
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: lightTheme,
    },
  },
  icons: {
    iconFont: "mdi",
  },
});
