<template>
  <div class="FileInputArea" :class="{ 'FileInputArea--disabled': disabled }">
    <div
      v-if="!value"
      class="file-input-area d-flex flex-column justify-center align-center pa-7 mb-3 rounded"
      @dragover.prevent="isDropAreaActive = !disabled && true"
    >
      <div
        v-show="isDropAreaActive"
        class="file-input-area__mask"
        @drop.prevent="handleFileDrop"
        @dragleave.prevent="isDropAreaActive = false"
      ></div>

      <v-icon large color="grey darken-1" class="pb-4"> {{ icon }} </v-icon>

      <slot>
        <p class="text-subtitle-1 font-weight-bold">Drop file here to import</p>
        <span class="text-subtitle-1 text--secondary">or</span>
        <label for="file-input" class="text-capitalize text-subtitle-1">
          Select file
        </label>
      </slot>

      <input
        id="file-input"
        :disabled="disabled"
        :value="''"
        :multiple="multiple"
        type="file"
        @input="handleFileInput"
      />
    </div>
    <v-file-input
      v-else
      :disabled="disabled"
      :value="value"
      :multiple="multiple"
      :error-messages="errorMessages"
      class="file-input"
      clear-icon="mdi-delete"
      @click:clear="onClickClear"
      @blur="$emit('blur')"
    />
  </div>
</template>

<script>
export default {
  name: "FileInputArea",

  props: {
    value: {
      type: File || Array,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    fileTypes: {
      type: Array,
      default: null,
    },
    errorMessages: {
      type: Array,
      default: null,
    },
    icon: {
      type: String,
      default: "mdi-download",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isDropAreaActive: false,
    };
  },

  methods: {
    handleFileDrop(e) {
      if (this.disabled) return;
      const files = e.dataTransfer?.files;
      this.isDropAreaActive = false;
      this.updateFile(files);
    },
    onClickClear() {
      this.$emit("change", null);
    },
    handleFileInput(e) {
      const files = e.target?.files;
      this.updateFile(files);
    },
    updateFile(files) {
      const value = this.multiple ? [...files] : files[0];
      this.$emit("change", value);
    },
  },
};
</script>

<style lang="scss">
.FileInputArea {
  &--disabled {
    opacity: 0.7;
  }

  .file-input-area {
    position: relative;
    border: 2px solid var(--v-background-darken1);
    background-color: #f0f0f0;

    label[for="file-input"] {
      color: var(--v-primary-base);
      cursor: pointer;
    }

    #file-input {
      height: 0;
      visibility: hidden;
    }

    &__mask {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
      border: 2px dashed var(--v-primary-base);
      opacity: 0.8;
    }
  }

  .file-input {
    .v-input__slot {
      .v-file-input__text {
        font-weight: 600;
        font-size: 16px;
        color: #01c6d0;
      }

      &::before {
        border: none !important;
      }
    }

    .v-input__icon--clear {
      opacity: 1;
    }
  }
}
</style>
