import PusherClient from "../libs/PusherClient";

export default {
  data() {
    return {
      pusherClient: null,
    };
  },

  mounted() {
    this.pusherClient = new PusherClient();
  },
};
