import { defineStore } from "pinia";
import { cloneDeep } from "lodash";
import { importCases } from "../services/cases";
import { useUserStore } from "./user";

const initialStatisticData = {
  total: 0,
  new: 0,
  duplicate: 0,
  fail: 0,
};

const initialData = {
  casesToImport: 0,
  casesImported: 0,
  isProgressModalVisible: false,
  isResultModalVisible: false,
  result: {
    cases: { ...initialStatisticData, data: [] },
    clinicians: { ...initialStatisticData, data: [] },
    clinicalCodes: { ...initialStatisticData, data: [] },
  },
  isImportFinished: false,
  shouldInitializeHandlers: false,
  fileName: null,
};

export const useCasesImportStore = defineStore("casesImport", {
  state() {
    return cloneDeep(initialData);
  },

  actions: {
    async startCasesImport(provider, file) {
      this.reset();
      this.isProgressModalVisible = true;
      this.fileName = file?.name || null;
      await importCases(provider, file);
      this.shouldInitializeHandlers = true;
    },
    addResultData(resultData) {
      Object.keys(resultData).forEach((key) => {
        if (this.result[key]) {
          Object.keys(resultData[key]).forEach((fieldKey) => {
            if (this.result[key][fieldKey] !== undefined) {
              if (fieldKey !== "data") {
                this.result[key][fieldKey] += resultData[key][fieldKey];
                return;
              }
              this.result[key][fieldKey].push(...resultData[key][fieldKey]);
            }
          });
        }
      });
    },
    showResult() {
      this.isResultModalVisible = true;
    },
    reset() {
      this.$reset();
    },
  },

  getters: {
    isImportInProgress({ isProgressModalVisible, isImportFinished }) {
      return !isImportFinished && isProgressModalVisible;
    },
    importProgress({ casesToImport, casesImported }) {
      if (!casesToImport) return 0;
      return Math.round((100 * casesImported) / casesToImport);
    },
    channelName() {
      const userStore = useUserStore();
      return `casesImport.${userStore.currentAccountId}`;
    },
  },
});
