import { axiosInstance } from "../setupInterceptors";

const endpointUrl = "/cases";

export const getCaseById = (id, params) => {
  return axiosInstance
    .get(`${endpointUrl}/${id}`, { params })
    .then((response) => response.data);
};

export const getCases = (params) => {
  return axiosInstance
    .get(endpointUrl, { params })
    .then((response) => response.data);
};

export const batchDeleteCases = (ids) => {
  return axiosInstance
    .delete(`${endpointUrl}/batch-delete`, {
      data: { ids }
    })
    .then((response) => response.data);
};

export const getGroupReviewById = (id) => {
  return axiosInstance
    .get(`${endpointUrl}/group-reviews/${id}`)
    .then((response) => response.data);
};

export const getProviderNames = () => {
  return axiosInstance
    .get(`${endpointUrl}/provider-names`)
    .then((response) => response.data);
};

export const getSelectOptions = (fields, service) => {
  return axiosInstance
    .get(`${endpointUrl}/selection-options`, { params: { fields, service } })
    .then((response) => response.data);
};

export const importCases = (serviceName, file) => {
  const formData = new FormData();
  formData.append("file", file);
  return axiosInstance
    .put(`${endpointUrl}/import?service=${serviceName}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);
};

export const addCaseNote = (caseId, body) => {
  return axiosInstance
    .post(`${endpointUrl}/${caseId}/notes`, body)
    .then((response) => response.data);
};

export const updateCaseNote = (caseId, noteId, body) => {
  return axiosInstance
    .patch(`${endpointUrl}/${caseId}/notes/${noteId}`, body)
    .then((response) => response.data);
};

export const updateCaseStatus = (body) => {
  return axiosInstance
    .post(`${endpointUrl}/change-status`, body)
    .then((response) => response.data);
};

export const getCaseHistoryLogs = (id) => {
  return axiosInstance
    .get(`${endpointUrl}/${id}/history-logs`)
    .then((response) => response.data);
};
