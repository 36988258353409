import VueRouter from "vue-router";
import {
  auth0Guard,
  setPageTitleMiddleware,
} from "./middlewares";
import { useUserStore } from "../stores/user";
import {
  PagesBreadCrumbTitles,
  RoutesMapping,
  AuthenticationPages,
} from "../misc/constants";
import ClinicalServicesPage from "@/views/clinicalService/ClinicalServicesPage.vue";
import AccountConfiguration from "@/views/accountConfiguration/AccountConfiguration.vue";

const HomePage = () => import("../views/home/HomePage.vue");
const CliniciansPage = () => import("../views/clinicians/CliniciansPage.vue");
const ClinicianPage = () => import("../views/clinician/ClinicianPage.vue");
const CasesPage = () => import("../views/cases/CasesPage.vue");
const CasePage = () => import("../views/case/CasePage.vue");
const AuthenticationPage = () =>
  import("../views/authentication/AuthenticationPage.vue");
const SpecialAuditPage = () =>
  import("../views/specialAudit/SpecialAuditPage.vue");
const DocAuditPage = () => import("../views/docAudit/DocAuditPage.vue");
const UsersPage = () => import("../views/users/UsersPage.vue");
const AccountsPage = () => import("../views/accounts/AccountsPage.vue");
const BatchesPage = () => import("../views/batches/BatchesPage.vue");
const BatchPage = () => import("../views/batch/BatchPage.vue");
const AuditTemplatesPage = () =>
  import("../views/auditTemplates/AuditTemplatesPage.vue");
const AuditTemplatePage = () =>
  import("../views/auditTemplate/AuditTemplatePage.vue");
const SupportPage = () => import("../views/support/Support.vue");
const NotFound = () => import("../views/notFound/NotFoundPage.vue");
const ReportingPage = () => import("../views/Reporting/ReportingPage.vue");


const rolesGuard = (to, from, next) => {
  const { user, isSuperAdmin, isAdmin, isAuditor, isClinician, isManager } = useUserStore();
  if (!user?._id) {
    return next({ name: AuthenticationPages.login });
  }

  if (isSuperAdmin) return next();

  if (isAdmin) {
    if (to.name === "accounts") {
      return next({ name: "notFound", params: { pathMatch: to.path } });
    }
    return next();
  }

  if (isAuditor) {
    if (
      to.name === "users" ||
      to.name === "auditTemplates" ||
      to.name === "auditTemplate" ||
      to.name === "accounts"
    ) {
      return next({ name: "notFound", params: { pathMatch: to.path } });
    }

    return next();
  }

  if (isManager) {
    if (
        to.name === "users" ||
        to.name === "auditTemplates" ||
        to.name === "auditTemplate" ||
        to.name === "accounts"
    ) {
      return next({ name: "notFound", params: { pathMatch: to.path } });
    }

    return next();
  }

  if (isClinician) {
    if (
      to.name === "case" ||
      to.name === "clinician" ||
      to.name === "support" ||
      to.name === "notFound"
    ) {
      return next();
    }

    if (to.name === "home") {
      return next({ name: "clinician", params: { id: user._id } });
    }

    return next({ name: "notFound", params: { pathMatch: to.path } });
  }
};

const loginPageGuard = (to, from, next) => {
  const { user } = useUserStore();
  if (user && Object.values(AuthenticationPages).includes(to.name)) {
    return next(from);
  }
  return next();
};

const getPageTitle = (title) => `Audit Angel - ${title}`;

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      meta: {
        title: getPageTitle("Home"),
      },
      component: HomePage,
      beforeEnter: rolesGuard,
    },
    {
      path: RoutesMapping[PagesBreadCrumbTitles.clinicians],
      name: "clinicians",
      meta: {
        title: getPageTitle("Clinicians"),
        breadcrumb: {
          title: PagesBreadCrumbTitles.clinicians,
        },
      },
      component: CliniciansPage,
      beforeEnter: rolesGuard,
    },
    {
      path: "/login",
      name: AuthenticationPages.login,
      meta: { title: getPageTitle("Login") },
      component: AuthenticationPage,
      beforeEnter: loginPageGuard,
    },
    {
      path: RoutesMapping[PagesBreadCrumbTitles.cases],
      name: "cases",
      meta: {
        title: getPageTitle("Cases"),
        breadcrumb: {
          title: PagesBreadCrumbTitles.cases,
        },
      },
      component: CasesPage,
      beforeEnter: rolesGuard,
    },
    {
      path: "/clinicians/:id",
      name: "clinician",
      meta: {
        title: getPageTitle("Clinician"),
      },
      component: ClinicianPage,
      beforeEnter: rolesGuard,
    },
    {
      path: "/cases/:id",
      name: "case",
      meta: { title: getPageTitle("Case") },
      component: CasePage,
      beforeEnter: rolesGuard,
    },
    {
      path: RoutesMapping[PagesBreadCrumbTitles.docAudit],
      name: "docAudit",
      meta: {
        title: getPageTitle("Clinical doc audit"),
        breadcrumb: {
          title: PagesBreadCrumbTitles.docAudit,
        },
      },
      component: DocAuditPage,
      beforeEnter: rolesGuard,
    },
    {
      path: RoutesMapping[PagesBreadCrumbTitles.specialAudit],
      name: "specialAudit",
      meta: {
        title: getPageTitle("Special audit"),
        breadcrumb: {
          title: PagesBreadCrumbTitles.specialAudit,
        },
      },
      component: SpecialAuditPage,
      beforeEnter: rolesGuard,
    },
    {
      path: "/users",
      name: "users",
      meta: { title: getPageTitle("Users") },
      component: UsersPage,
      beforeEnter: rolesGuard,
    },
    {
      path: "/configuration",
      name: "accountConfiguration",
      meta: { title: getPageTitle("Account configuration") },
      component: AccountConfiguration,
      beforeEnter: rolesGuard,
    },
    {
      path: "/accounts",
      name: "accounts",
      meta: { title: getPageTitle("Accounts") },
      component: AccountsPage,
      beforeEnter: rolesGuard,
    },
    {
      path: "/clinical-services",
      name: "clinical-services",
      meta: { title: getPageTitle("Services") },
      component: ClinicalServicesPage,
      beforeEnter: rolesGuard,
    },
    {
      path: RoutesMapping[PagesBreadCrumbTitles.batches],
      name: "batches",
      meta: {
        title: getPageTitle("Batches"),
        breadcrumb: {
          title: PagesBreadCrumbTitles.batches,
        },
      },
      component: BatchesPage,
      beforeEnter: rolesGuard,
    },
    {
      path: "/batches/:id",
      name: "batch",
      meta: { title: getPageTitle("Batch") },
      component: BatchPage,
      beforeEnter: rolesGuard,
    },
    {
      path: "/batches/:id/statistic",
      name: "batchStatistic",
      meta: { title: getPageTitle("Batch statistic") },
      component: BatchPage,
      beforeEnter: rolesGuard,
    },
    {
      path: "/audit-templates",
      name: "auditTemplates",
      meta: { title: getPageTitle("Audit Templates") },
      component: AuditTemplatesPage,
      beforeEnter: rolesGuard,
    },
    {
      path: "/audit-templates/:id",
      name: "auditTemplate",
      meta: { title: getPageTitle("Edit Audit Template") },
      component: AuditTemplatePage,
      beforeEnter: rolesGuard,
    },
    {
      path: "/support",
      name: "support",
      "meta": { title: getPageTitle("Support") },
      component: SupportPage,
      beforeEnter: rolesGuard,
    },
    {
      path: "/reporting",
      name: "reporting",
      meta: { title: getPageTitle("Reporting") },
      component: ReportingPage,
      beforeEnter: rolesGuard,
    },
    {
      path: "*",
      name: "notFound",
      meta: {
        title: getPageTitle("NotFound"),
      },
      component: NotFound,
      beforeEnter: rolesGuard,
    },
  ],
});

router.beforeEach(auth0Guard);
router.beforeEach(setPageTitleMiddleware);

export default router;
