<template>
  <ul class="ListSummary d-flex pa-0">
    <li>All {{ entity }} - {{ statistics.total }}</li>
    <li>{{ entity }} with Error - {{ statistics.fail }}</li>
    <li>Duplicated {{ entity }} - {{ statistics.duplicate }}</li>
    <li>Successfully imported {{ entity }} - {{ statistics.new }}</li>
  </ul>
</template>

<script>
export default {
  props: {
    entity: {
      type: String,
      required: true,
    },
    statistics: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.ListSummary {
  list-style: none;

  li {
    margin-left: 20px;

    &::before {
      display: inline-block;
      content: "";
      height: 10px;
      width: 10px;
      border-radius: 50%;
      margin-right: 8px;
    }

    &:nth-child(2)::before {
      background-color: #ff8f8e;
    }

    &:nth-child(3)::before {
      background-color: #ffd463;
    }

    &:nth-child(4)::before {
      background-color: #cad084;
    }

    &:first-child {
      margin-left: 0;

      &::before {
        display: none;
      }
    }
  }
}
</style>
