import Vue from "vue";
import VueRouter from "vue-router";
import { PiniaVuePlugin, createPinia } from "pinia";
import Notifications from "vue-notification";
import VueApexCharts from "vue-apexcharts";
import vuetify from "./plugins/vuetify";
import sentry from "./plugins/sentry";
import router from "./router";
import App from "./App.vue";
import { setup as setupInterceptors } from "./services/setupInterceptors";
import "./styles/main.scss";
import { Auth0Plugin } from "./services/auth/auth0wrapper";

Vue.config.productionTip = false;

Vue.component("apexchart", VueApexCharts);
Vue.use(Notifications);
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
Vue.use(VueRouter);

Vue.use(sentry, {
  router,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  propagationTargets: [/^https:\/\/api-?.*audit-angel\.co\.uk\/api/],
});

Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
});

setupInterceptors();

new Vue({
  vuetify,
  router,
  pinia,
  render: (h) => h(App),
}).$mount("#app");
