import { defineStore } from "pinia";

export const useAccountStore = defineStore("account", {
  state() {
    return {
      currentAccount: null,
      encodedImgLogo: null,
    };
  },
  actions: {
    setCurrentAccount(account) {
      this.currentAccount = account;
    },
    setEncodedImgLogo(base64) {
      this.encodedImgLogo = base64;
    },
  },
  getters: {
    getCurrentAccount: (state) => state.currentAccount,
    getEncodedLogoImage: (state) => state.encodedImgLogo,
  },
});
