<template>
  <v-app>
    <AppHeader v-if="user" @click="toggleBurgerMenu" />
    <router-view :key="$route.fullPath" />
    <transition name="menu" appear>
      <BurgerMenu
        v-if="isBurgerMenuActive && $vuetify.breakpoint.mobile"
        @toggle="toggleBurgerMenu"
      />
    </transition>
    <AppFooter v-if="user" />
    <ImportProgressBar v-if="isProgressModalVisible && user" />
    <!--<ChatComponent v-if="user"/>-->
    <notifications
      classes="app-notifications"
      width="300"
      position="bottom center"
      :ignore-duplicates="true"
    />
  </v-app>
</template>

<script>
import { RouterView } from "vue-router";
import { mapState } from "pinia";
import { useUserStore } from "./stores/user";
import { useCasesImportStore } from "./stores/casesImport";
import AppHeader from "./components/header/AppHeader.vue";
import AppFooter from "./components/footer/AppFooter.vue";
import BurgerMenu from "./components/header/BurgerMenu.vue";
import ImportProgressBar from "./components/case/ImportProgressBar.vue";

export default {
  name: "App",

  components: {
    RouterView,
    AppHeader,
    BurgerMenu,
    AppFooter,
    ImportProgressBar,
  },

  data() {
    return {
      isBurgerMenuActive: false,
    };
  },

  computed: {
    ...mapState(useUserStore, ["user"]),
    ...mapState(useCasesImportStore, ["isProgressModalVisible"]),
  },

  watch: {
    "$vuetify.breakpoint.mobile": function (newIsMobile) {
      if (newIsMobile && this.isBurgerMenuActive) {
        this.toggleBurgerMenu(false);
      }
    },
  },

  methods: {
    toggleBurgerMenu(value) {
      this.isBurgerMenuActive = value;
    },
  },
};
</script>

<style lang="scss">
.menu-enter-active,
.menu-leave-active {
  transition: 0.3s;
}
.menu-enter {
  transform: translate(100%, 0);
}

.menu-leave-to {
  transform: translate(0, 100%);
}
</style>
