import { axiosInstance } from "../setupInterceptors";

const endpointUrl = "/clinical-services";

const extractData = (response) => response.data;

export const getClinicalServicesWithPaginate = (params = {}) =>
  axiosInstance.get(endpointUrl, { params }).then(extractData);

export const getClinicalServices = (params = {}) =>
    axiosInstance.get(`${endpointUrl}/list`, { params }).then(extractData);

export const getClinicalServiceById = (id, params = {}) =>
  axiosInstance.get(`${endpointUrl}/${id}`, { params }).then(extractData);

export const createClinicalService = (payload) =>
  axiosInstance.post(endpointUrl, payload).then(extractData);

export const updateClinicalServiceById = (id, payload) =>
  axiosInstance.patch(`${endpointUrl}/${id}`, payload).then(extractData);

export const createMultipleServices = (payload) =>
  axiosInstance.post(`${endpointUrl}/multiple`, payload).then(extractData);
